import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import ImprovingQualityArticle from '../pages-partials/article-improving-quality/ImprovingQualityArticle';
import ImprovingQualityArticleHero from '../pages-partials/article-improving-quality/ImprovingQualityArticleHero';

const ImprovingQualityPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('ImprovingQualitySeoTitle')}
      description={t('ImprovingQualitySeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/improving-quality"
      lang={locale}
    />
    <ImprovingQualityArticleHero />
    <ImprovingQualityArticle />
  </Layout>
);

export default withI18next({ ns: 'common' })(ImprovingQualityPage);
