import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center">{t('ArticlePiece2Title')}</h1>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
