import { Link } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import urls from '../../components/urls';
import Asset24 from '../../images/Asset-24.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <p>{t('ImprovingQualityArticlePara1')}</p>
          <p>{t('ImprovingQualityArticlePara2')}</p>
          <h2 className="text-blue">{t('ImprovingQualityArticleTitle1')}</h2>
          <p>{t('ImprovingQualityArticlePara3')}</p>
          <p>{t('ImprovingQualityArticlePara4')}</p>
          <p>
            {t('ImprovingQualityArticlePara5')}
            <Link to={urls.softwareUrl}>{t('LinkToSoftware')}</Link>
          </p>
          <h2 className="text-blue">{t('ImprovingQualityArticleTitle2')}</h2>
          <p>{t('ImprovingQualityArticlePara6')}</p>
          <p>{t('ImprovingQualityArticlePara7')}</p>
          <h2 className="text-blue">{t('ImprovingQualityArticleTitle3')}</h2>
          <p>{t('ImprovingQualityArticlePara8')}</p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={6} className="mt-5">
          <ContactRequest />
        </Col>
        <Col lg={6}>
          <img
            src={Asset24}
            alt={t('ArticlePiece2Alt')}
            className="img img-w-350 block m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={urls.articlePageUrl}>{t('BackToArticles')}</Link>
        </Col>
      </Row>
    </Container>
  );
};
